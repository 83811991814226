/**
 * Dynamic Slideover Controller
 * 
 * This Stimulus controller facilitates dynamic content updates within specified target elements
 * on the page, based on user interactions (e.g., clicking a button or link). It's particularly useful
 * for implementing features like modal dialogs or slide-over panels where content needs to be
 * dynamically changed based on which trigger element (like a button or link) was clicked.
 * 
 * HOW TO USE:
 * 1. Add data-controller="dynamic-slideover slideover" AND 
 *    data-action="dynamic-slideover:prepare->slideover#toggle"
 *    to the container that encompasses both the trigger elements (e.g., buttons or links) 
 *    and the content groups.
 * 2. For each trigger element, add data-action="dynamic-slideover#prepare"
 *    AND data-element-id="UNIQUE_ID". The UNIQUE_ID should correspond to the content group
 *    that you want to show based on this trigger.
 * 3. Within the content groups, use data-dynamic-slideover-element-id="UNIQUE_ID" to
 *    match these groups with their triggers.
 * 4. For individual elements inside these groups that should have their content copied
 *    to targets, use data-dynamic-slideover-target="TARGET_ELEMENT_ID", where
 *    TARGET_ELEMENT_ID is the id of the target element where content will be copied to.
 * 
 * EXAMPLE:
 * 
 * <div data-controller="dynamic-slideover slideover" data-action="dynamic-slideover:prepare->slideover#toggle">
 *  <!-- Trigger Element -->
 *  <button data-action="click->dynamic-slideover#prepare" data-element-id="1">Show Details</button>
 * 
 *  <!-- Content Groups -->
 *  <div>
 *    <div data-dynamic-slideover-element-id="1">
 *      <p data-dynamic-slideover-target="target-detail">Detail Content for Item 1</p>
 *    </div>
 *    <div data-dynamic-slideover-element-id="2">
 *      <p data-dynamic-slideover-target="target-detail">Detail Content for Item 2</p>
 *    </div>
 *  </div>
 * 
 *  <!-- Target Element -->
 *  <div id="target-detail"></div>
 * </div> 

 * With this setup, clicking the button will find the content within the div that has a matching
 * `data-dynamic-slideover-element-id` and will copy the `<p>` tag's content to the div with the
 * id of `target-detail`.
 * 
 * This allows for a flexible content management system where the content associated with each
 * trigger element can be easily updated or changed without modifying the JavaScript.
 */


import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Connect!") // Debug
  }

  prepare_read_more(event) {
    event.preventDefault()
    
    this.prepare_slideover(event)

    // Now execute the actual slideover toggle
    this.dispatch("ready")
  }

  prepare_settings(event) {
    event.preventDefault();
    console.log("Prepare Settings Connect!"); // Debug

    this.prepare_slideover(event)

    // The ID for the new turbo-frame we want to create
    const frameId = "new_settings_account_integration";

    // The target container where the turbo-frame will be added
    const target = document.getElementById("slide-over-body");

    // Ensure the target container exists
    if (target) {
        // Clear out any existing content in the target container
        target.innerHTML = '';

        // Create a new turbo-frame element
        const frame = document.createElement("turbo-frame");
        frame.id = frameId;

        // Append the new turbo-frame to the target container
        target.appendChild(frame);

        // Get the URL from the clicked element
        const url = event.currentTarget.getAttribute('href');

        // Set the src attribute of the turbo-frame to load the form
        frame.setAttribute("src", url);

        // Dispatch event to show the slideover
        this.dispatch("ready");
    } else {
        console.error("Target element not found");
    }
  }


  prepare_slideover(event) {
    // Retrieve the elementID from the clicked element
    const elementId = event.target.dataset.elementId;
    
    // Find all elements that have a matching `data-dynamic-slideover-element-id`
    const sourceGroup = document.querySelector(`[data-dynamic-slideover-element-id="${elementId}"]`);

    if (!sourceGroup) {
      console.error(`No elements found with data-dynamic-slideover-element-id="${elementId}"`);
      return;
    }

    // For each source element within the identified group, find its corresponding target and copy content
    sourceGroup.querySelectorAll("[data-dynamic-slideover-target]").forEach((sourceElement) => {
      const targetId = sourceElement.dataset.dynamicSlideoverTarget;
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        targetElement.textContent = sourceElement.textContent;
      } else {
        console.error(`Target element #${targetId} not found`);
      }
    });
  }
}